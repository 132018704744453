<template>
  <div :style="marginTop">
    <div class="page-title">方片库存透视</div>
    <!-- 选项 -->
    <div style="float:left;margin-top: 1rem;" v-show="tableData.length===0">
      <el-col :span="24">
        <el-tooltip content="来源厂家" placement="top" transition="none" effect="light">
          <el-select
            v-model="colScreen.来源厂家"
            clearable
            multiple
            allow-create
            default-first-option
            filterable
            placeholder="-来源厂家-"
            style="width:150px"
          >
            <el-option
              v-for="item in 来源厂家组"
              :value="item"
              :label="item"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="波段" placement="top" transition="none" effect="light">
          <el-select
            v-model="colScreen.BoDuan"
            clearable
            placeholder="-波段-"
            style="width:120px"
            @change="BoDuanChg"
          >
            <el-option
              v-for="item in BoDuanList"
              :key="item.label"
              :value="item.label"
              :label="item.label"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="型号" placement="top" transition="none" effect="light">
          <el-select v-model="colScreen.XingHao" clearable placeholder="-型号-" style="width:130px">
            <el-option
              v-for="item in XingHaoList"
              :key="item"
              :value="item"
              :label="item"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="VF1 AVG" placement="top" transition="none" effect="light">
          <el-select
            v-model="colScreen.VF1_AVG"
            placeholder="VF1 AVG"
            filterable
            allow-create
            clearable
            default-first-option
            style="width:120px"
            @change="chgSelect('VF1_AVG')"
          >
            <el-option value="2.9-3.5" label="2.9-3.5"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="VF1 AVG 步长" placement="top" transition="none" effect="light" style="width:100px">
          <el-input
            clearable
            placeholder="VF1步长"
            v-model="colScreen.VF1_AVG_Step"
          ></el-input>
        </el-tooltip>
        <el-tooltip content="LOP1 AVG" placement="bottom" transition="none" effect="light">
          <el-select
            v-model="colScreen.LOP1_AVG"
            placeholder="LOP1 AVG"
            filterable
            allow-create
            clearable
            default-first-option
            style="width:130px"
            @change="chgSelect('LOP1_AVG')"
          >
            <el-option value="500-1180" label="500-1180"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="LOP1 AVG 步长" placement="bottom" transition="none" effect="light" style="width:110px">
          <el-input
            clearable
            placeholder="LOP1步长"
            v-model="colScreen.LOP1_AVG_Step"
          ></el-input>
        </el-tooltip>
        <el-tooltip :content="GetAbb" placement="bottom" transition="none" effect="light">
          <el-select
            v-model="colScreen.WLP1_AVG"
            :placeholder="GetAbb"
            filterable
            allow-create
            clearable
            default-first-option
            style="width:130px"
            @change="chgSelect('WLP1_AVG')"
          >
            <el-option value="390-420" label="390-420"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip :content="GetAbb+'步长'" placement="bottom" transition="none" effect="light" style="width:115px">
          <el-input
            clearable
            :placeholder="GetAbb.slice(0,4)+'步长'"
            v-model="colScreen.WLP1_AVG_Step"
          ></el-input>
        </el-tooltip>
        <el-button type="success" plain @click="showPv">透视</el-button>
      </el-col>
    </div>
    <!-- 按钮 -->
    <div style="float:left;width:100%;margin-top:1rem" v-show="tableData.length>0">
      <el-button
        type="danger"
        icon="el-icon-back"
        @click="Back"
      >返回
      </el-button>
    </div>
    <div style="float:left;width:100%;margin-top:1rem" v-show="tableData.length===0">
      <div style="float:left;width:100%;" v-for="w in BoDuanList">
        <h4 style="margin:0;padding:5px 0 5px 0">{{ w.label }}</h4>
        <el-button
          type="primary"
          size="mini"
          plain
          style="margin:0 3px 3px 0"
          v-for="b in w.value"
          @click="ShowIt(w.label,b)"
          v-show="BtnShow(w.label,b)"
        >{{ w.label }}/{{ b }}
        </el-button>
      </div>
    </div>
    <!-- 透视表格 -->
    <div style="float:left;margin-top:1rem;" v-for="table in tableData">
      <div style="width:100%;float:left;padding:10px 0;font-weight:550;">{{ table.标题 }}</div>
      <div style="float:left;overflow-x:scroll" :style="divWidth">
        <table>
          <tr v-for="(row,index) in table.行" :style="rowStyle(index)">
            <td v-for="td in row" @dblclick="ShowSquare(td.condition)">{{ td.text }}</td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 弹出层 -->
    <el-dialog
      :visible.sync="showLayer"
      fullscreen
    >
      <square-table ref="pv" my-name="pv" :table-data="layerData" @change-screen="SubScreenChg"/>
      <el-pagination
        style="margin-top:1rem"
        @current-change="ChgPage"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
      <div style="float:left;width:100%;padding:0.5rem 0">
        <el-button style="margin-right:2rem" type="primary" size="mini" plain @click="OutPutData">导出数据</el-button>
        合计数量：{{ count }} 片，颗粒数 {{ sumQty.toLocaleString() }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseTitle, baseUrl, BoDuanList, squarePvRanges,} from '@/configs/squareConfig'
import {getSquarePv, squareGetPvData} from '@/http/api'
import SquareTable from "../components/SquareTable";
import {formRequest} from "@/http/formRequest";

export default {
  name: "ProdSquareSkuPv",
  components: {SquareTable},
  data() {
    return {
      token: this.$cookies.get('token'),
      BoDuanList,
      XingHaoList: [],
      colScreen: {
        来源厂家: [],
        VF1_AVG_Step: '',
        LOP1_AVG_Step: '',
        WLP1_AVG_Step: '',
      },
      tableData: [],
      ranges: squarePvRanges,
      divWidth: '',
      showLayer: false,
      layerData: [],
      page: 1,
      pageSize: 100,
      count: 0,
      form: {},
      marginTop: '',
      记忆位置: 0,
      sumQty: 0,
      来源厂家组: ['<排除>', '<空白>', '圆融', '兆元', '兆元20A'],
    }
  },
  methods: {
    showPv() {
      const top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      this.记忆位置 = top
      let colScreen = this.colScreen
      if (
        !colScreen.BoDuan ||
        !colScreen.XingHao ||
        !colScreen.VF1_AVG ||
        !colScreen.VF1_AVG_Step ||
        !colScreen.LOP1_AVG ||
        !colScreen.LOP1_AVG_Step ||
        !colScreen.WLP1_AVG ||
        !colScreen.WLP1_AVG_Step
      ) {
        this.$message({
          type: 'warning',
          message: '请填写完整',
        })
        return
      }
      const token = this.token
      getSquarePv({token, colScreen})
        .then(res => {
          if (res.data.code === 'OK') {
            this.tableData = res.data.tableData
            if (this.tableData.length === 0) {
              this.$message({
                type: 'warning',
                message: '暂无对应的数据'
              })
            } else {
              window.scroll(0, 0)
            }
          }
        })
        .catch(err => console.log(err))
    },
    rowStyle(index) {
      if (index < 2) return 'background:#ecf0f1'
    },
    BoDuanChg() {
      const b = this.colScreen.BoDuan
      this.XingHaoList = []
      delete this.colScreen.XingHao
      if (b) {
        const f = this.BoDuanList.find(item => item.label === b)
        this.XingHaoList = f.value
      }
    },
    chgSelect(s) {
      if (s === 'VF1_AVG') {
        this.colScreen.VF1_AVG_Step = 0.1
      }
      if (s === 'LOP1_AVG') {
        this.colScreen.LOP1_AVG_Step = 45
      }
      if (s === 'WLP1_AVG') {
        this.colScreen.WLP1_AVG_Step = 10
      }
    },
    ShowIt(a, b) {
      let 来源厂家 = this.colScreen.来源厂家
      let key = a + b
      let getItem = this.ranges.find(item => item.型号 === key)
      if (getItem) {
        this.colScreen = {
          来源厂家,
          BoDuan: a,
          XingHao: b,
          VF1_AVG: getItem.取值.电压,
          VF1_AVG_Step: getItem.取值.电压步长,
          LOP1_AVG: getItem.取值.亮度,
          LOP1_AVG_Step: getItem.取值.亮度步长,
          WLP1_AVG: getItem.取值.波长,
          WLP1_AVG_Step: getItem.取值.波长步长,
        }
        this.showPv()
      } else {
        this.$message({
          type: "warning",
          message: `${key} 暂未配置默认取值`
        })
      }
    },
    ShowSquare(condition) {
      if (!condition) return
      const form = {}
      form.波段 = this.colScreen.BoDuan
      form.型号 = this.colScreen.XingHao
      form.电压范围 = condition.电压 || this.colScreen.VF1_AVG
      form.波长范围 = condition.波段 || this.colScreen.WLP1_AVG
      form.亮度范围 = condition.亮度 || this.colScreen.LOP1_AVG
      form.来源厂家 = this.colScreen.来源厂家
      this.page = 1
      this.form = form
      this.GetSquarePvData(true)
    },
    GetSquarePvData(clear) {
      squareGetPvData({
        form: this.form,
        page: this.page,
        token: this.token,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.layerData = res.data.rs
            this.page = res.data.page
            this.pageSize = res.data.pageSize
            this.count = res.data.count
            this.sumQty = res.data.sumQty
            this.showLayer = true
            if (clear) setTimeout(() => this.$refs.pv.colScreen = {}, 100)
          }
        })
        .catch(err => console.log(err))
    },
    ChgPage(p) {
      this.page = p
      this.GetSquarePvData()
    },
    SubScreenChg(payload) {
      const screen = JSON.parse(JSON.stringify(payload))
      let Qty = screen.Qty || ''
      delete screen.myName
      delete screen.Qty
      let keys = Object.keys(screen)
      if (keys.length !== 0) {
        this.$message({
          type: 'warning',
          message: '查看器以被双击的单元格所对应的条件为准，不支持范围筛选'
        })
        this.$refs.pv.colScreen = {
          myName: payload.myName,
          Qty,
        }
      }
      if (Qty) {
        this.form.Qty = Qty
        this.page = 1
        this.GetSquarePvData()
      }
    },
    Back() {
      this.tableData = []
      window.scrollTo(0, this.记忆位置)
      this.记忆位置 = 0
    },
    OutPutData() {
      const form = JSON.stringify(this.form)
      formRequest(baseUrl + '/square-op-pv-data', {token: this.token, form})
    },
  },
  computed: {
    BtnShow() {
      return (a, b) => {
        return this.ranges.find(item => item.型号 === a + b)
      }
    },
    GetAbb() {
      const 波段 = this.colScreen.BoDuan
      if (
        波段 === '蓝光' ||
        波段 === '绿光' ||
        波段 === '黄绿光' ||
        波段 === 'RGB'
      ) return 'WLD1 AVG'
      else return 'WLP1 AVG'
    },
  },
  activated() {
    document.title = '方片库存透视 - ' + baseTitle
    const w = window.innerWidth - 80
    this.divWidth = `width:${w}px`
    window.onresize = () => {
      const w = window.innerWidth - 80
      this.divWidth = `width:${w}px`
    }
    const obj = document.querySelector('.el-menu-demo')
    setTimeout(() => this.marginTop = `margin-top:${obj.clientHeight - 50}px`, 100)
  },
}
</script>

<style scoped>
table, tr, td {
  border: solid 1px #ccc;
  border-collapse: collapse;
}

table tr td {
  padding: 8px 5px;
  line-height: 1.5;
  white-space: nowrap;
}
</style>
